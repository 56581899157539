var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"pb-1 pr-8",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',{staticClass:"ml-n4 form_container"},[_c('v-container',[(_vm.originalNotaNF)?_c('v-row',[_vm._l((_vm.nfFields),function(col,k){return [_c('input-v',{key:k,staticClass:"pl-4",attrs:{"colSize":col.colSize,"editable":col.editable,"label":col,"valid":_vm.validation.get(col.key),"value":_vm.notaNF[col.key],"opts":_vm.opts},on:{"input":function($event){return _vm.updateValue(_vm.notaNF, col.key, $event)},"update:valid":function($event){return _vm.updateValidation(col.key, $event)}}})]}),_c('v-col',{attrs:{"cols":12}},[_c('v-btn',{staticClass:"btn-plus-item",attrs:{"depressed":""},on:{"click":function($event){return _vm.createItem()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Novo Item ")],1)],1),(!_vm.notaNF.itens.length)?_c('v-col',{attrs:{"cols":12}},[_c('span',[_vm._v("Nenhum item cadastrado nesta nota.")])]):_c('expansion-panel',{attrs:{"contentProps":{ eager: true },"headerProps":{ color: '#81b4ea' },"items":_vm.notaNF.itens,"panelProps":_vm.expansionPanelProps},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
var itemIndex = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(item.descricao)+" ")]),(_vm.getItemValidation(itemIndex))?_c('span',{staticClass:"has-erros"},[_vm._v(" "+_vm._s(_vm.getItemValidation(itemIndex))+" erros ")]):_vm._e(),_c('v-btn',{staticClass:"btn-content-duplicate-item",attrs:{"depressed":"","icon":"","title":"Duplicar item e suas depreciações"},on:{"click":function($event){$event.stopPropagation();return _vm.createItem(item)}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),(_vm.canDelete)?[(item.delete)?_c('v-btn',{staticClass:"btn-content-duplicate-item",attrs:{"depressed":"","icon":"","title":"Desfazer exclusão"},on:{"click":function($event){$event.stopPropagation();item.delete = false}}},[_c('v-icon',[_vm._v("mdi-undo-variant")])],1):_c('v-btn',{staticClass:"btn-content-duplicate-item",attrs:{"depressed":"","icon":"","title":"Excluir item"},on:{"click":function($event){$event.stopPropagation();item.delete = true}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]:_vm._e()]}},{key:"content",fn:function(ref){
var item = ref.item;
var itemIndex = ref.index;
return [_c('v-col',{attrs:{"cols":12}},[_c('v-row',[_vm._l((_vm.itemFields),function(col,k){return [_c('input-v',{key:(itemIndex + "-" + k),staticClass:"pl-4",attrs:{"value":item[col.key],"valid":_vm.validation.get(((col.key) + ".itens." + itemIndex)),"label":col,"opts":_vm.opts,"colSize":col.colSize,"editable":col.editable},on:{"input":function($event){return _vm.updateValue(item, col.key, $event)},"update:valid":function($event){return _vm.updateValidation(
                        ((col.key) + ".itens." + itemIndex),
                        $event
                      )}}})]}),_c('v-col',{attrs:{"cols":12}},[_c('v-btn',{staticClass:"btn-plus-depre",attrs:{"depressed":""},on:{"click":function($event){return _vm.createDepre(item)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nova Depreciação ")],1)],1),(!item.depreciacoes.length)?_c('v-col',{attrs:{"cols":12}},[_c('span',[_vm._v("Nenhuma depreciação cadastrada neste item.")])]):_c('expansion-panel',{attrs:{"contentProps":{ eager: true },"headerProps":{ color: '#6bb4ba' },"items":item.depreciacoes,"panelProps":_vm.expansionPanelProps},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                      var depre = ref.item;
                      var depreIndex = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDepreciationTitle(depre))+" — "),(depre.competencia)?[_vm._v(" "+_vm._s(_vm._f("toMonth")(depre.competencia))+" ")]:[_vm._v(" Sem competência ")]],2),(_vm.getDepreciationValidation(itemIndex, depreIndex))?_c('span',{staticClass:"has-erros"},[_vm._v(" "+_vm._s(_vm.getDepreciationValidation(itemIndex, depreIndex))+" erros ")]):_vm._e(),_c('v-btn',{staticClass:"btn-content-duplicate-depre",attrs:{"depressed":"","icon":"","title":"Duplicar depreciação para próxima competência"},on:{"click":function($event){$event.stopPropagation();return _vm.createDepre(item, depre)}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),(_vm.canDelete)?[(depre.delete)?_c('v-btn',{staticClass:"btn-content-duplicate-depre",attrs:{"depressed":"","icon":"","title":"Desfazer exclusão"},on:{"click":function($event){$event.stopPropagation();depre.delete = false}}},[_c('v-icon',[_vm._v("mdi-undo-variant")])],1):_c('v-btn',{staticClass:"btn-content-duplicate-depre",attrs:{"depressed":"","icon":"","title":"Excluir depreciação"},on:{"click":function($event){$event.stopPropagation();depre.delete = true}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]:_vm._e()]}},{key:"content",fn:function(ref){
                      var depre = ref.item;
                      var depreIndex = ref.index;
return [_c('v-col',{attrs:{"cols":12}},[_c('v-row',[_vm._l((_vm.depreciationFields),function(col,k){return [_c('input-v',{key:(itemIndex + "-" + depreIndex + "-" + k),staticClass:"pl-4",attrs:{"value":depre[col.key],"valid":_vm.validation.get(
                                ((col.key) + ".itens." + itemIndex + ".depreciacoes." + depreIndex)
                              ),"label":col,"opts":_vm.opts,"colSize":col.colSize,"editable":col.editable},on:{"input":function($event){return _vm.updateValue(depre, col.key, $event)},"update:valid":function($event){return _vm.updateValidation(
                                ((col.key) + ".itens." + itemIndex + ".depreciacoes." + depreIndex),
                                $event
                              )}}})]})],2)],1)]}}],null,true)})],2)],1)]}}],null,false,696704332)})],2):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-3",attrs:{"color":"secondary","depressed":"","large":""},on:{"click":function($event){return _vm.onBack()}}},[(_vm.hasChanges)?[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-alpha-x-box")]),_vm._v(" Cancelar ")]:[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar ")]],2),(_vm.hasChanges)?_c('v-btn',{staticClass:"px-3",attrs:{"color":"primary","depressed":"","dense":"","large":""},on:{"click":_vm.onSave}},[(_vm.isCreate)?[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus-box")]),_vm._v(" Criar ")]:[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v(" Salvar ")]],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }