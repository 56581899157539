<template>
  <v-card>
    <v-card-title style="display: block" class="pb-1 pr-8">
      {{ title }}
    </v-card-title>
    <v-card-text class="ml-n4 form_container">
      <v-container>
        <v-row v-if="originalNotaNF">
          <!-- Formulário da nota -->
          <template v-for="(col, k) in nfFields">
            <input-v
              class="pl-4"
              :colSize="col.colSize"
              :editable="col.editable"
              :key="k"
              :label="col"
              :valid="validation.get(col.key)"
              :value="notaNF[col.key]"
              :opts="opts"
              @input="updateValue(notaNF, col.key, $event)"
              @update:valid="updateValidation(col.key, $event)"
            ></input-v>
          </template>

          <!-- Formulário dos itens -->
          <v-col :cols="12">
            <v-btn class="btn-plus-item" depressed @click="createItem()">
              <v-icon>mdi-plus</v-icon>
              Novo Item
            </v-btn>
          </v-col>
          <v-col v-if="!notaNF.itens.length" :cols="12">
            <span>Nenhum item cadastrado nesta nota.</span>
          </v-col>
          <expansion-panel
            v-else
            :contentProps="{ eager: true }"
            :headerProps="{ color: '#81b4ea' }"
            :items="notaNF.itens"
            :panelProps="expansionPanelProps"
          >
            <template v-slot:header="{ item, index: itemIndex }">
              <span>
                {{ item.descricao }}
              </span>
              <span v-if="getItemValidation(itemIndex)" class="has-erros">
                {{ getItemValidation(itemIndex) }} erros
              </span>
              <v-btn
                class="btn-content-duplicate-item"
                depressed
                icon
                title="Duplicar item e suas depreciações"
                @click.stop="createItem(item)"
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
              <template v-if="canDelete">
                <v-btn
                  v-if="item.delete"
                  class="btn-content-duplicate-item"
                  depressed
                  icon
                  title="Desfazer exclusão"
                  @click.stop="item.delete = false"
                >
                  <v-icon>mdi-undo-variant</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  class="btn-content-duplicate-item"
                  depressed
                  icon
                  title="Excluir item"
                  @click.stop="item.delete = true"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </template>
            <template v-slot:content="{ item, index: itemIndex }">
              <v-col :cols="12">
                <v-row>
                  <template v-for="(col, k) in itemFields">
                    <input-v
                      class="pl-4"
                      :key="`${itemIndex}-${k}`"
                      :value="item[col.key]"
                      :valid="validation.get(`${col.key}.itens.${itemIndex}`)"
                      :label="col"
                      :opts="opts"
                      :colSize="col.colSize"
                      :editable="col.editable"
                      @input="updateValue(item, col.key, $event)"
                      @update:valid="
                        updateValidation(
                          `${col.key}.itens.${itemIndex}`,
                          $event
                        )
                      "
                    ></input-v>
                  </template>

                  <!-- Formulário das depreciações -->
                  <v-col :cols="12">
                    <v-btn
                      class="btn-plus-depre"
                      depressed
                      @click="createDepre(item)"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Nova Depreciação
                    </v-btn>
                  </v-col>
                  <v-col v-if="!item.depreciacoes.length" :cols="12">
                    <span>Nenhuma depreciação cadastrada neste item.</span>
                  </v-col>
                  <expansion-panel
                    v-else
                    :contentProps="{ eager: true }"
                    :headerProps="{ color: '#6bb4ba' }"
                    :items="item.depreciacoes"
                    :panelProps="expansionPanelProps"
                  >
                    <template v-slot:header="{ item: depre, index: depreIndex }">
                      <span>
                        {{ getDepreciationTitle(depre) }} —
                        <template v-if="depre.competencia">
                          {{ depre.competencia | toMonth }}
                        </template>
                        <template v-else> Sem competência </template>
                      </span>
                      <span v-if="getDepreciationValidation(itemIndex, depreIndex)" class="has-erros">
                        {{ getDepreciationValidation(itemIndex, depreIndex) }} erros
                      </span>
                      <v-btn
                        class="btn-content-duplicate-depre"
                        depressed
                        icon
                        title="Duplicar depreciação para próxima competência"
                        @click.stop="createDepre(item, depre)"
                      >
                        <v-icon>mdi-content-duplicate</v-icon>
                      </v-btn>
                      <template v-if="canDelete">
                        <v-btn
                          v-if="depre.delete"
                          class="btn-content-duplicate-depre"
                          depressed
                          icon
                          title="Desfazer exclusão"
                          @click.stop="depre.delete = false"
                        >
                          <v-icon>mdi-undo-variant</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          class="btn-content-duplicate-depre"
                          depressed
                          icon
                          title="Excluir depreciação"
                          @click.stop="depre.delete = true"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                    </template>
                    <template
                      v-slot:content="{ item: depre, index: depreIndex }"
                    >
                      <v-col :cols="12">
                        <v-row>
                          <template v-for="(col, k) in depreciationFields">
                            <input-v
                              class="pl-4"
                              :key="`${itemIndex}-${depreIndex}-${k}`"
                              :value="depre[col.key]"
                              :valid="
                                validation.get(
                                  `${col.key}.itens.${itemIndex}.depreciacoes.${depreIndex}`
                                )
                              "
                              :label="col"
                              :opts="opts"
                              :colSize="col.colSize"
                              :editable="col.editable"
                              @input="updateValue(depre, col.key, $event)"
                              @update:valid="
                                updateValidation(
                                  `${col.key}.itens.${itemIndex}.depreciacoes.${depreIndex}`,
                                  $event
                                )
                              "
                            ></input-v>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </expansion-panel>
                </v-row>
              </v-col>
            </template>
          </expansion-panel>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="px-3" color="secondary" depressed large @click="onBack()">
          <template v-if="hasChanges">
            <v-icon class="mr-1">mdi-alpha-x-box</v-icon>
            Cancelar
          </template>
          <template v-else>
            <v-icon class="mr-1">mdi-arrow-left</v-icon>
            Voltar
          </template>
        </v-btn>

        <v-btn
          v-if="hasChanges"
          class="px-3"
          color="primary"
          depressed
          dense
          large
          @click="onSave"
        >
          <template v-if="isCreate">
            <v-icon class="mr-1">mdi-plus-box</v-icon>
            Criar
          </template>
          <template v-else>
            <v-icon class="mr-1">mdi-content-save</v-icon>
            Salvar
          </template>
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import getDifferences from "@/helpers/getDifferences";

export default {
  components: {
    "expansion-panel": () => import("@/components/expansion-panel.vue"),
    "input-v": () => import("@/components/input-v.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    canDelete: function () {
      return this.userHasAccessToAction('delete');
    },
    depreciationFields: function () {
      return [
        {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "projetos", key: "id", name: "titulo" },
          colSize: 9,
          rules: [{ rule: "required" }],
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 3,
          rules: [{ rule: "required" }],
        },
        {
          key: "justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXTAREA,
          colSize: 12,
        },
      ];
    },
    expansionPanelProps: function () {
      return (item) => ({
        disabled: item.delete,
      });
    },
    hasChanges: function () {
      const diff = getDifferences(this.originalNotaNF, this.notaNF);
      return !isEmpty(diff);
    },
    isCreate: function () {
      return this.nfId === 'novo';
    },
    itemFields: function () {
      return [
        {
          key: "descricao",
          name: "Descrição do Item",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "valor",
          name: "Valor do Item",
          type: this.$fieldTypes.MONEY,
          colSize: 3,
          rules: [{ rule: "required" }],
        },
        {
          key: "valor_deprec",
          name: "Valor Depreciado",
          type: this.$fieldTypes.MONEY,
          editable: false,
          colSize: 3,
        },
      ];
    },
    nfFields: function () {
      return [
        {
          key: "nro_documento",
          name: "Nº Documento",
          type: this.$fieldTypes.TEXT,
          colSize: 12,
          rules: [{ rule: "required" }],
        },
        {
          key: "terceiroId",
          name: "Fornecedor",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "terceiros", key: "id", name: "razao_social" },
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "valor_nf",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          colSize: 3,
          rules: [{ rule: "required" }],
        },
        {
          key: "data",
          name: "Emissão",
          type: this.$fieldTypes.MONTH,
          colSize: 3,
          rules: [{ rule: "required" }],
        },
        {
          key: "finalidade",
          name: "Classificação LI",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "finalidades", key: "value", name: "value" },
          colSize: 8,
        },
        {
          key: "periodo_depreciacao",
          name: "Período de Depreciação",
          type: this.$fieldTypes.NUMBER,
          colSize: 4,
          rules: [
            { rule: "required" },
            { rule: "between", params: { min: 1, max: 120 } },
          ],
        },
      ];
    },
    nfId: function () {
      return this.$route.params.nfId;
    },
    resource: function () {
      return this.apiResource(`/v1/fiscal/nfdepreciacao/${this.clientId}`);
    },
    sumItemsValue: function () {
      return (
        this.notaNF.itens?.reduce((prev, curr) => prev + curr.valor, 0) || 0
      );
    },
    title: function () {
      return this.$route.meta.pageTitle || "";
    },
  },
  created: function () {
    if (this.nfId && !this.isCreate) {
      this.resource.get(this.nfId).then((response) => {
        this.notaNF = cloneDeep(response);
        this.originalNotaNF = cloneDeep(response);
      });
    }

    this.apiResource(`v1/projetos/${this.clientId}/selecao`)
      .get()
      .then((response) => (this.opts.projetos = response.projetos));
    this.apiResource(`v1/fiscal/terceiro/${this.clientId}/material`)
      .get()
      .then((response) => (this.opts.terceiros = response));
  },
  data: function () {
    const notaNF = {
      nro_documento: null,
      terceiroId: null,
      valor_nf: "0",
      data: null,
      finalidade: null,
      periodo_depreciacao: null,
      itens: [],
    };

    return {
      invalidFields: [],
      notaNF,
      validation: new Map(),
      originalNotaNF: cloneDeep(notaNF),
      opts: {
        finalidades: [
          { value: "EQUIPAMENTOS" },
          { value: "SOFTWARE (COMPRA)" },
          { value: "SOFTWARE (LICENÇA DE USO)" },
        ],
        projetos: [],
        terceiros: [],
      },
    };
  },
  methods: {
    createDepre: function (item, depreRef = undefined) {
      let depre = {
        projetoId: null,
        competencia: moment().format("YYYY-MM"),
        justificativa: "",
      };

      if (depreRef) {
        depre = cloneDeep(depreRef);
        const nextComp = depre.competencia
          ? moment(depre.competencia).add(1, "month")
          : moment();
        depre.competencia = nextComp.format("YYYY-MM");
        depre.justificativa =
          `Duplicado da competência ${moment(depre.competencia).format(
            "YYYY-MM"
          )}.\n` + depre.justificativa;
        delete depre.id;
      }

      item.depreciacoes.push(depre);
    },
    createItem: function (itemRef = undefined) {
      let item = {
        descricao: "Novo item",
        valor: 0,
        valor_deprec: "0",
        depreciacoes: [],
      };

      if (itemRef) {
        item = cloneDeep(itemRef);
        item.descricao = "Duplicado — " + item.descricao;
        delete item.id;

        if (item.depreciacoes?.length > 0) {
          item.depreciacoes.forEach((depre) => {
            delete depre.id;
          });
        }
      }

      this.notaNF.itens.push(item);
    },
    checkFormValidation: function () {
      this.invalidFields = [];
      this.validation.forEach((validFn, key) => {
        if (!validFn()) {
          this.invalidFields.push(key);
        }
      });
      return this.invalidFields;
    },
    getDepreciationTitle: function (depreciation) {
      if (depreciation?.projetoId) {
        const projeto = this.opts.projetos.find((projeto) => projeto.id === depreciation.projetoId);
        return projeto.titulo;
      }
      return "Nova depreciação";
    },
    getItemValidation: function (itemIndex) {
      const rg = new RegExp(`.\\.itens.${itemIndex}`);
      return this.invalidFields.filter((field) => rg.test(field)).length;
    },
    getDepreciationValidation: function (itemIndex, depreIndex) {
      const rg = new RegExp(`.\\.itens.${itemIndex}.depreciacoes.${depreIndex}`);
      return this.invalidFields.filter((field) => rg.test(field)).length;
    },
    onBack: function () {
      this.$router.push({ name: "listagem-dispendio-equipamentos-softwares" });
    },
    onSave: function () {
      const invalidFields = this.checkFormValidation();
      if (!invalidFields.length) {
        const id = this.isCreate ? undefined : this.nfId;
        const body = cloneDeep(this.notaNF);
        this.resource.save(body, id).then((response) => {
          if (!response.error) {
            if (this.isCreate) {
              this.$router.push({ name: "edicao-dispendio-equipamentos-softwares", params: { nfId: response.id } });
            } else {
              this.$router.go(0);
            }
          }
        });
      }
    },
    updateValorDeprec: function () {
      this.itemFields[2].editable = true;
      let { periodo_depreciacao, valor_nf, itens } = this.notaNF;
      this.notaNF.itens = [];
      periodo_depreciacao = parseFloat(periodo_depreciacao, 10);
      valor_nf = parseFloat(valor_nf, 10);
      const valor_deprec = (valor_nf || 0) / (periodo_depreciacao || 1);

      itens = itens.map((item) => {
        item.valor_deprec = `${valor_deprec}`;
        return item;
      });
      this.itemFields[2].editable = false;
      this.notaNF.itens = itens;
      this.$forceUpdate();
    },
    updateValue: function (target, key, value) {
      if (key === "valor_deprec") {
        return;
      }

      if (key in target) {
        target[key] = value;
      }

      if (["periodo_depreciacao", "valor_nf"].includes(key)) {
        this.updateValorDeprec();
      }
    },
    updateValidation: function (key, value) {
      this.validation.set(key, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  position: relative;
}

::v-deep {
  $text-color: #fff;
  $item-panel: #81b4ea;
  $depre-panel: #6bb4ba;

  .theme--light.v-sheet {
    background-color: transparent;
    border-color: transparent;
  }

  span.has-erros {
    color: red;
  }

  .btn-plus-item.v-btn.v-btn--depressed.theme--light.v-size--default {
    background-color: $item-panel;
    box-shadow: 0px 3px 1px -2px $item-panel, 0px 2px 2px 0px $item-panel,
      0px 1px 5px 0px $item-panel;
  }

  .btn-plus-depre.v-btn.v-btn--depressed.theme--light.v-size--default {
    background-color: $depre-panel;
    box-shadow: 0px 3px 1px -2px $depre-panel, 0px 2px 2px 0px $depre-panel,
      0px 1px 5px 0px $depre-panel;
  }

  .btn-plus-depre.v-btn.v-btn--depressed.theme--light.v-size--default,
  .btn-plus-item.v-btn.v-btn--depressed.theme--light.v-size--default {
    color: $text-color;
    float: right;
  }

  .v-expansion-panel-header {
    color: $text-color;
    justify-content: space-between;
  }

  .btn-content-duplicate-item,
  .btn-content-duplicate-depre {
    &.v-btn.v-btn--depressed.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default {
      flex: 0 1 36px;
  
      ::before {
        color: $text-color;
      }
    } 
  }

  .theme--light.v-expansion-panels
    .v-expansion-panel-header
    .v-expansion-panel-header__icon
    .v-icon {
    color: $text-color;
  }

  .v-card__actions {
    padding-right: 24px;
    padding-left: 24px;
    position: static;

    .v-btn {
      position: sticky;
      bottom: 16px;
      z-index: 6;
    }
  }
}
</style>
